<template>
  <div>
    <h2>Информация о пользователе</h2>
    <h4>Логин: {{ authUser.username }}</h4>
    <h4>ФИО: {{ authUser.fullName }}</h4>
    <h4>Организация : {{ authUser.organization.name }}</h4>
    <h4>Должность: {{ authUser.userPosition }}</h4>
    <h4 v-if="authUser.managerName !== null">
      Руководитель: {{ authUser.managerName }}
    </h4>
    <h4>Номер телефона: {{ authUser.phoneNumber }}</h4>
    <h4>Роль: {{ authUser.role.displayValue }}</h4>
    <!--    <h4>Организация: {{authUser.organization.organizationTitle}}</h4>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "userProfile",
  computed: {
    ...mapGetters({
      authUser: "GETAUTHUSER",
    }),
  },
};
</script>

<style scoped></style>
