<template>
  <div>
    <user-profile></user-profile>
  </div>
</template>

<script>
import UserProfile from "@/components/userProfile";
export default {
  name: "userProfileView",
  components: { UserProfile },
};
</script>

<style scoped></style>
